<template>
  <div class="more-gift" v-if="setting.showMoreGift">
    <img @click="startrotate" class="main-img" src="../../assets/gift-main.gif" alt="" />
    <img @click="openRecord" v-tracking="{ userVisit: '8' }" src="../../assets/record.png" class="record-btn" />
    <img @click="openRule" src="../../assets/rule.png" class="rule-btn" />
    <img @click="startrotate" v-tracking="drawTrackingParams" src="../../assets/btn.png" class="btn" />

    <!-- 中奖记录 -->
    <div v-if="recordVisible" class="record-dialog">
      <div class="record-content">
        <div class="title">中奖记录</div>
        <div class="wrap">
          <div class="record-list">
            <div class="item" v-for="(item, index) in recordList" :key="index" @click="hrefUrl(item.skipUrl)">
              <span class="name">{{ item.prizeName }}</span>
              <span class="time">{{ item.createTime }}</span>
            </div>
            <div class="no-more">暂无更多记录</div>
          </div>
        </div>
        <div class="close" @click="closeRecord" v-tracking="{ userVisit: '10' }">
          <img src="../../assets/close-btn.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 活动规则 -->
    <div v-if="ruleVisible" class="rule-dialog">
      <div class="rule-content">
        <div class="title">活动规则</div>
        <div class="wrap">
          <div class="rule-list" v-html="ruleText">
          </div>
        </div>
        <div class="close" @click="closeRule">
          <img src="../../assets/close-btn.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="more-gift-skeleton">
      <img src="@/assets/more-gift-skeleton.png" alt="更多好礼" />
    </div>
  </div>
</template>
<script>
import { winningRecordAfter, drawRecordAfter,getRuleText } from '@/api/index.js'
import { toLink } from '@/utils/index.js'
import { getSessionStorage } from '@/utils/storage.js'
import setting from '@/setting.js'

export default {
  data() {
    return {
      recordVisible: false,
      ruleVisible: false,

      recordList: [], //中奖记录
      openState: true, // 防止重复点击
      drawTrackingParams: {
        userVisit: '3',
        back:
          getSessionStorage('afterBack') ||
          function () {
            return 'null'
          }
      },
      setting,
      ruleText: ""
    }
  },
  props: [],
  created() {
    this.getRule();
  },
  methods: {
    hrefUrl(url) {
      window.location.href = url
      // toLink(url, 'coupons')
    },
    //点击抽奖,后置转盘,实际都只有电子奖
    startrotate() {
      var that = this

      if (this.openState) {
        this.openState = false

        // 如果是谢谢惠顾页面抽奖携带back值为2
        let resultBack = {}
        if (this.$route.name == 'thanksPatronize') {
          resultBack.back = 2
        } else if (sessionStorage.getItem('afterBack') != 'null') {
          resultBack.back = sessionStorage.getItem('afterBack')
        }
        drawRecordAfter(resultBack).then(
          res => {
            this.$emit('drawAfter')
            this.openState = true
            if (
              res.data.drawResult.isTrue == 1 ||
              res.data.drawResult.isTrue == 0
            ) {
              // 后置转盘实际都只有电子奖
              // window.location.href = res.data.drawResult.skipUrl; // 0电子奖||1实物奖

              // 如果是谢谢惠顾页面
              if (this.$route.name == 'thanksPatronize') {
                toLink(res.data.drawResult.skipUrl)
              } else if (this.$route.query.isNoBack) {
                toLink(res.data.drawResult.skipUrl)
              } else {
                toLink(res.data.drawResult.skipUrl, 'coupons')
              }
            } else if (res.data.drawResult.isTrue == 3) {
              this.$router.push({
                path: '/redEnvelopes',
                query: {
                  prizeId: res.data.drawResult.prizeId,
                  drawLogId: res.data.drawResult.drawLogId
                }
              })
            }
          },
          function () {
            that.openState = true
          }
        )
      }

    },
    // 关闭记录
    closeRecord() {
      this.recordVisible = false
    },

    // 打开中奖记录
    openRecord() {
      this.recordVisible = true
      console.log('打开中奖记录')
      winningRecordAfter().then(({ data }) => {
        this.recordList = data
      })
    },
    // 打开规则
    openRule() {
      this.ruleVisible = true
    },
    closeRule() {
      this.ruleVisible = false
    },
     getRule() {
      getRuleText().then(({ data }) => {
        let text = data.ruleText ? data.ruleText : ""
        this.ruleText = text.replace(/&lt;%= TITLE %&gt;/ig, setting.title)
      })
    }
  }
}
</script>
<style scoped lang="less">
.more-gift {
  width: 100%;
  position: relative;

  .main-img {
    width: 100%;
    display: block;
  }

  .record-btn {
    position: absolute;
    top: 10px;
    left: 0;
    width: 152px;
    height: 55px;
  }

  .rule-btn {
    position: absolute;
    top: 10px;
    right: 0;
    width: 152px;
    height: 54px;
  }

  .btn {
    position: absolute;
    top: 204px;
    left: 50%;
    margin-left: -138px;
    width: 276px;
    height: 74px;

    animation: move 0.8s 0s infinite;
    -webkit-animation: move 0.8s 0s infinite;
  }

  // 抽奖记录

  .record-dialog {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index:300;

    .record-content {
      width: 640px;
      height: 862px;
      background: url('../../assets/dialog-bg.png') no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      padding-top: 17px;

      position: relative;
      z-index: 999;

      .title {
        font-size: 44px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #974614;
        line-height: 36px;
        text-align: center;
        margin-bottom: 80px;
      }

      .wrap {
        width: 100%;
        height: 710px;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 10px 0;
        box-sizing: border-box;

        .record-list {
          width: 100%;

          .item {
            display: flex;
            margin-bottom: 16px;

            .name {
              width: 50%;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 32px;
              margin-left: 62px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-right: 30px;
              box-sizing: border-box;
            }

            .time {
              width: 50%;
              font-size: 24px;
              font-family: Helvetica;
              font-weight: 400;
              color: #666666;
              line-height: 32px;
            }
          }

          .no-more {
            text-align: center;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 36px;
            margin-top: 35px;
          }
        }
      }

      .close {
        width: 67px;
        height: 67px;
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translate(-50%);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // 活动规则
  .rule-dialog {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index:300;

    .rule-content {
      width: 640px;
      height: 862px;
      background: url('../../assets/dialog-bg.png') no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      padding-top: 17px;

      position: relative;
      z-index: 999;

      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 44px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #974614;
        line-height: 36px;
        text-align: center;
        margin-bottom: 80px;
      }

      .wrap {
        width: 600px;
        height: 710px;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
        padding: 10px 35px;

        .rule-list {
          width: 100%;

          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
        }
      }

      .close {
        width: 67px;
        height: 67px;
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translate(-50%);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.more-gift-skeleton {
  margin-top: -40px;
}

@keyframes move {
  0% {
    // transform: translate(-50%, -50%);
    transform: scale(1.08);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.08);
  }
}
</style>
